import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { matchRoutes, useLocation } from 'react-router-dom';

import { useDecisionById } from 'components/Experiment/useDecisionById';
import { URGENCY_BANNER } from 'constants/monetateExperiments';
import urls from 'constants/urls';
import { getCustomerHasAnOrderRequiringPaymentAttention } from 'redux/modules/orders/selectors';

export function useUrgencyBannerType():
  | ['payment', null]
  | ['info' | 'warning' | 'danger', object]
  | [null, null] {
  const customerHasAnOrderRequiringPaymentAttention = useSelector(
    getCustomerHasAnOrderRequiringPaymentAttention,
  );

  const location = useLocation();
  const isMyOrdersPage = useMemo(
    () =>
      !!matchRoutes(
        [
          { path: urls.myOrdersPage },
          { path: urls.viewOrder },
          { path: urls.checkout },
          { path: urls.resolveOrderPayment },
        ],
        location,
      ),
    [location],
  );

  const { variant, payload } = useDecisionById(URGENCY_BANNER.experiment);

  if (
    customerHasAnOrderRequiringPaymentAttention &&
    !isMyOrdersPage &&
    variant !== URGENCY_BANNER.variant.danger
  ) {
    return ['payment', null];
  }

  switch (variant) {
    case URGENCY_BANNER.variant.info:
      return ['info', payload];
    case URGENCY_BANNER.variant.warning:
      return ['warning', payload];
    case URGENCY_BANNER.variant.danger:
      return ['danger', payload];
    default:
      return [null, null];
  }
}
